import './About.scss';

import Page from '@components/Page';

function About(props) {

  return (
    <Page name='about'>
      <h1>About coolthx.com</h1>
      <p></p>
    </Page>
  );
}

export default About;
